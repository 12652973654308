// Function to read a cookie value by its name.
const readCookie = function( cookieName ) {

	// Encode the cookieName to handle special characters.
	cookieName = encodeURIComponent( cookieName );

	// Prepare the cookies string, replacing spaces with semicolons for easier splitting.
	let cookies = ( ";" + document.cookie ).split( " " ).join( ";" );

	let startIndex = cookies.indexOf( ";" + cookieName + "=" ),
		endIndex = startIndex < 0 ? startIndex : cookies.indexOf( ";", startIndex + 1 );

	return ( startIndex < 0 ) ? "" : decodeURIComponent( cookies.substring( startIndex + 2 + cookieName.length, endIndex < 0 ? cookies.length : endIndex ) )

};


// Function to unescape a given string, specifically handling URL-encoded strings.
const reverseEscapeString = function( encodedString ) {
	
	// Return the input directly if it's falsy (e.g., null, undefined, empty string).
	if ( !encodedString ) return encodedString;
	
	// Replace '+' with ' ' (space) if '+' exists in the string, as '+' represents a space in URL encoding.
	encodedString = encodedString.includes( "+" ) ? encodedString.replace( /\+/g, " " ) : encodedString;
	
	try {

		// Attempt to decode the URL-encoded string.
		return decodeURIComponent( encodedString );
	
	} catch ( error ) {
	
		// If decoding fails, log the error (optional) and return the original encoded string.
		// Note: The original recursive call to unescape(encodedString) could lead to infinite recursion if decodeURIComponent always fails.
		// It's safer to return the encodedString or handle the error differently.
		console.warn( "Error decoding URI component:", error );
		return encodedString;
	
	}
};


/**
 * Read Key-Value pair from specified cookie and return value
 * @param {string} cookieName
 * @param {string} keyName
 * @param {string} keySplit
 * @returns {string}
 * example: readCookieKeyValue("s_sess", "s_pv_pName", ";");
 */

const readCookieKeyValue = function( cookieName, keyName, keySplit = ";" ) {
	let key;
	let value = "";
	let pair = [];

	// returns the decoded cookie value or an empty string if the cookie is not found
	let cookieString = readCookie( cookieName );

	let cookieArray = cookieString.split( keySplit );

	for ( let i = 0; i < cookieArray.length; i++ ) {
		pair = cookieArray[ i ].split( '=' );

		key = pair[ 0 ].trim();

		if ( key === keyName ) {
			value = reverseEscapeString( pair[ 1 ] ).trim();
			break;
		}
	}

	return value;

};


const getDeviceType = function() {

	return _aape.deviceType;

};


const getBusinessType = function () {

	return _aape.siteBusinessType;

};

const getStoreNumber = function () {
	
	let storeID = "",
		cookieString = readCookie( "THD_LOCALIZER" ),
		cookieObj = '',
		cookieArray = [];

	// exit early if no cookie found		
	if ( !cookieString ) return storeID;
		
	cookieObj = JSON.parse( decodeURIComponent( cookieString ) );

	if ( cookieObj.THD_LOCSTORE ) {

		cookieArray = cookieObj.THD_LOCSTORE.split("+");
		
		storeID = ( Array.isArray( cookieArray ) && cookieArray.length ? cookieArray[0] : "" );

	}

	return storeID;
	
};


const isConsumerApp = function () {

	return window.navigator.userAgent.toLowerCase().indexOf("thdconsumer/") !== -1;

};


const isPEP = function () {

	return window.location.pathname.indexOf("/pep/") !== -1; 

};


const isPIP = function () {

	return window.location.pathname.indexOf("/p/") !== -1; 

};


/**
 * List values (array) within a given object, no matter level of JSON complexity
 * @param objData
 * @param out - can pass in an empty array or nothing at all if no matchKey
 * @param matchKey optional string value, if found, will return all values of that key found
 * @returns {*|Array}
 * examples:
 *   getAllValues(digitalData.transaction,[],'sku')
 *       > this returns all values of keys named "sku" in the transaction node of digitalData
 *   getAllValues(digitalData)
 *       > this returns all values of all keys in digitalData object (used to see if any have values assigned (digitalData in use)
 */
const getAllValues = function( objData, out = [], matchKey = '' ) {

	var keyVal;

	for ( var key in objData ) {
		keyVal = objData[ key ];

		var nodetype = typeof ( objData[ key ] );
		if ( nodetype === 'object' || nodetype === 'array' ) {
			out = getAllValues( objData[ key ], out, matchKey );
		} else {
			if ( keyVal !== undefined && typeof ( keyVal ) !== "function" ) {
				if ( matchKey === '' || matchKey && matchKey === key ) {
					out.push( keyVal );
				}
			}
		}
	}
	return ( out );
};


export {
	getBusinessType,
	getDeviceType,
	getAllValues,	
	getStoreNumber,
	isConsumerApp,
	isPEP,
	isPIP,	
	readCookie,
	readCookieKeyValue
};
